import { useUI, Text } from '@components/ui'
import cn from 'classnames'
import { getAdCookie } from '@lib/utils/cookies'
import { useEffect, useRef, useState } from 'react'
import { regExp } from '@lib/utils/regExp'
import fetcher from '@lib/fetcher'
import { I18N_STORE_DOMAIN } from '@shopify/const'
import { pageGTMEvent } from '@lib/utils/thirdparty'
import { easingTransitions } from 'framework/lib/utils/animate'
import { sha256 } from '@lib/utils/tools'
import s from './index.module.css'
import { useRelativeLink } from '@commerce/product/use-relative-link'
function Subscribe({ data, metafields, locale, pageType, slug, device }) {
  let timer = null
  const {
    footerSetting = {},
    footerMenu = {},
    copywriting,
    footerCountry = {},
    preRender = {},
  } = metafields
  const { setHtmlRelativeLink } = useRelativeLink()

  const [disabled, setDisabled] = useState(true)
  const [email, setEmail] = useState('')
  const [isError, setIsError] = useState(true)
  const [formError, setFormError] = useState('')
  const [policy, setPolicy] = useState(false)
  const [shakeAn, setShake] = useState(false)
  const [scrollPosition, setScrollPosition] = useState(0)
  const [isImpression, setIsImpression] = useState(false)

  const btnRef = useRef(null)
  const targetRef = useRef(null)
  const showFormError = ({ type, error }) => {
    setFormError(error || copywriting[type] || 'unknown error')
    if (timer) clearTimeout(timer)
    timer = setTimeout(() => {
      setFormError('')
    }, 3000)
  }

  const footerCategory = () => {
    switch (pageType) {
      case 'powerStation':
        return 'footer_sub_pps'
      case 'charger':
        return 'footer_sub'
      case 'hes':
        return 'footer_sub_hes'
    }
  }

  const { recaptchaReady } = useUI()

  useEffect(() => {
    if (recaptchaReady) {
      setDisabled(false)
    }
  }, [recaptchaReady])

  useEffect(() => {
    if (targetRef.current) {
      const targetPosition =
        targetRef.current.getBoundingClientRect().top + window.pageYOffset
      const windowHeight = window.innerHeight
      // 判断是否滚动到目标元素的位置
      if (scrollPosition > targetPosition - windowHeight && !isImpression) {
        // 在此执行您的操作
        // console.log('已滚动到目标元素位置');
        pageGTMEvent({
          event: 'uaEvent',
          eventCategory: footerCategory(), //中充页脚传footer_sub_pps
          eventAction: '',
          eventLabel: 'impression',
          nonInteraction: true,
        })
        pageGTMEvent({ event_parameters: null })
        pageGTMEvent({
          event: 'ga4Event',
          event_name: 'lp_button',
          event_parameters: {
            page_group: footerCategory(), //中充页脚传footer_sub_pps
            position: '',
            button_name: 'impression',
          },
        })
        setIsImpression(true)
      }
    }
  }, [scrollPosition])

  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleSubscribe = async () => {
    setIsError(true)

    if (isOpen) {
      setIsOpen(false)
      return null
    }

    if (!email) {
      return showFormError({ type: 'fillEmail' })
    }
    if (!regExp.email.test(email)) {
      return showFormError({ type: 'errEmail' })
    }
    if (!policy) {
      setShake(true)
      return showFormError({ type: 'agreePolicy' })
    }
    setDisabled(true)
    const { register_source } = getAdCookie()
    const sub_brand_type_content = () => {
      let text = ''
      switch (pageType) {
        case 'powerStation':
          return (text = 'anker_powerhouse')
        case 'charger':
          return (text = '')
        case 'hes':
          return (text = 'anker_HES_balkonkraftwerk')
      }
    }
    const brandDealsType = () => {
      switch (pageType) {
        case 'powerStation':
          return 'anker_powerhouse'
        case 'charger':
          return 'anker_charger'
        case 'hes':
          return 'anker_balcony'
        default:
          return 'footer_sub'
      }
    }
    const result = await fetcher({
      locale,
      url: '/api/multipass/rainbowbridge/activities',
      method: 'POST',
      action: 'activity',
      needRecaptcha: true,
      body: {
        email: email.toLowerCase(),
        register_source,
        shopify_domain: I18N_STORE_DOMAIN[`${locale}`],
        single_brand_subscribe: true,
        genre: brandDealsType(),
      },
    })
    setDisabled(false)
    if (!result.errors) {
      showFormError({ type: 'footerSuccess' })
      setEmail('')
      setIsError(false)
      setIsOpen(true)
      setShake(false)
      pageGTMEvent({
        subscribe_hashed_email: email ? sha256(email.toLowerCase()) : '',
      })
      pageGTMEvent({
        event: 'ga4Event',
        event_name: 'subscribe',
        event_parameters: {
          page_group: `Home Page${pageType == 'charger' ? '' : '_pps'}`,
          positon: 'footer',
        },
      })
      pageGTMEvent({ event_parameters: null })
      pageGTMEvent({
        event: 'uaEvent',
        eventCategory: 'subscribe',
        eventAction: 'footer',
        page_group: `Home Page${pageType == 'charger' ? '' : '_pps'}`,
        eventLabel: slug,
        nonInteraction: false,
      })
    } else {
      setShake(false)
      showFormError({ error: result.errors || result.statusText })
    }
  }

  const [isOpen, setIsOpen] = useState(false)
  if (!data) return null
  return (
    <div className="mt-[24px] l:flex l:flex-col l:items-center l:text-center">
      {data?.title && (
        <Text
          className="text-[18px] font-semibold leading-[22px] text-[#fff] md:text-[14px] md:leading-[16px] l-xl:text-[14px] l-xl:leading-[16px] md-l:text-[16px] md-l:leading-[20px]"
          html={data?.title}
        ></Text>
      )}
      <div className="relative mt-[16px] w-full max-w-[338px]">
        <input
          value={email}
          onChange={(e) => setEmail(e.target.value?.trim())}
          type="email"
          placeholder={data?.placeholder}
          className="h-[48px] w-full rounded-[50px] border border-[#A1A1A1] bg-[#141414] px-[16px] text-[14px] leading-[16px] text-[#fff] focus:border-[#A1A1A1] focus:outline-none md:text-[12px] md:leading-[14px] l-xl:text-[12px] l-xl:leading-[14px] md-l:text-[14px] md-l:leading-[16px]"
        />
        <div
          onClick={handleSubscribe}
          className={cn(
            'absolute right-[6px] top-[50%] h-[36px] w-[36px] translate-y-[-50%] cursor-pointer',
            `${disabled ? 'pointer-events-none cursor-not-allowed' : ''}`
          )}
        >
          <svg
            className="h-full w-full"
            xmlns="http://www.w3.org/2000/svg"
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
          >
            <rect
              width="36"
              height="36"
              rx="18"
              className=" hover:fill-[#fff]"
              fill={disabled ? '#999' : '#F5F5F7'}
            />
            <path
              d="M17 13L22 18L17 23"
              stroke="#141414"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        </div>
      </div>
      <div className="mt-[14px]">
        <div className="flex items-start">
          <label htmlFor={`customCheckbox_${device}`}>
            <input
              id={`customCheckbox_${device}`}
              onChange={(e) => setPolicy(e.target.checked)}
              type="checkbox"
              className={cn('hidden', s?.custom_checkbox)}
            />
            <div className={s?.checkbox_label}></div>
          </label>
          <Text
            className="ml-1 text-left text-[14px] font-medium leading-[18px] text-[#A1A1A1]"
            html={setHtmlRelativeLink({ html: data?.tips })}
          ></Text>
        </div>
      </div>
      <div className="mt-[10px]">
        <Text
          html={formError}
          className={cn(
            'min-h-[30px] text-[12px] font-medium text-[#ff0101]',
            `${isError ? 'text-[#FF0101]' : 'text-[#00CA2C]'}`
          )}
          style={{ opacity: formError ? 1 : 0 }}
        ></Text>
      </div>
    </div>
  )
}

export default Subscribe
