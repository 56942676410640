import Icon from '@components/icons'
import { Picture } from '@components/ui'
import { MinusIcon, PlusIcon, XMarkIcon } from '@heroicons/react/24/outline'
import cn from 'classnames'
import { useState, useEffect } from 'react'
import styles from './Footer.module.css'
import { Text, useUI } from '@components/ui'
import Subscribe from './Subscribe'
import CountryModalComp from '../FooterNew/CountryModalComp'
import { useRelativeLink } from '@commerce/product/use-relative-link'

const HesFooter = ({
  metafields,
  data,
  pageType,
  subScribeData,
  slug,
  locale,
}) => {
  const [index, setIndex] = useState()
  const { countryModal, setCountryModal } = useUI()
  const { setRelativeLink } = useRelativeLink()

  const handleLiveChat = (e) => {
    e.preventDefault()
    const sidebar = document.querySelector('.helpButtonEnabled')
    if (sidebar) {
      sidebar.click()
    }
  }

  const COUNTRY_CHOOSE = (className = '') => {
    return (
      <>
        {footerCountry?.current_country && (
          <div className={`${styles.country_wrap} ${className}`}>
            {/* <p className={styles.current_country_text}>{data?.select}</p> */}
            <button
              className={styles.country}
              onClick={() =>
                setCountryModal({
                  visible: true,
                  position: 'footer',
                })
              }
            >
              <div className={styles.current_country_btn}>
                <Icon
                  iconKey="countries"
                  className="h-[17px] w-[17px] fill-white"
                />
                <p className={cn(styles.current_country, 'mt-[4px]')}>
                  {footerCountry?.current_country}
                </p>
              </div>
            </button>
          </div>
        )}
      </>
    )
  }

  const [isActive, setIsActive] = useState(false)
  const {
    footerSetting = {},
    footerMenu = {},
    copywriting,
    infos = {},
    footerCountry = {},
  } = metafields
  return (
    <section
      className={cn(styles.footer_layer, 'layer', 'global_footer_layer')}
    >
      <div className={cn(styles.footer_content, 'content')}>
        <div className={cn(styles.footer_menu_all)}>
          {data?.menu_list &&
            data?.menu_list.map((v, i) => (
              <div key={i} className={cn(styles.footer_menu)}>
                <div
                  className={styles.footer_menu_title_mob}
                  onClick={() => {
                    setIndex(i)
                    if (index == i) {
                      setIsActive(!isActive)
                    } else {
                      setIsActive(true)
                    }
                  }}
                >
                  <p
                    className={styles.footer_menu_title}
                    dangerouslySetInnerHTML={{
                      __html: v.title,
                    }}
                  ></p>

                  <div className="relative hidden md:block">
                    {index == i && isActive ? (
                      <MinusIcon className="h-[12px] w-[12px] text-white " />
                    ) : (
                      <PlusIcon className="h-[12px] w-[12px] text-white" />
                    )}
                  </div>
                </div>
                <div
                  className={cn(
                    {
                      [styles.footer_menu_text_box_show]:
                        index == i && isActive,
                    },
                    {
                      [styles.footer_menu_text_box_hide]:
                        index !== i || !isActive,
                    },

                    styles.footer_menu_text_box
                  )}
                >
                  {v.list &&
                    v.list.map((item, index) => (
                      <p key={index} className={styles.footer_menu_a_box}>
                        <a
                          className={styles.footer_menu_a}
                          href={`${setRelativeLink({
                            link: item?.link,
                          })}?ref=footer${
                            pageType === 'powerStation' || pageType === 'hes'
                              ? '_pps'
                              : ''
                          }`}
                          target={item.target}
                          dangerouslySetInnerHTML={{
                            __html: item.text,
                          }}
                          rel={item?.rel}
                        ></a>
                      </p>
                    ))}
                </div>
              </div>
            ))}
          <div className={cn(styles?.footer_menu, '!mr-0 l:hidden')}>
            {COUNTRY_CHOOSE(styles.desktop_hide)}
            <Subscribe
              device="desktop"
              pageType={pageType}
              metafields={metafields}
              locale={locale}
              data={subScribeData}
              slug={slug}
            ></Subscribe>
            <div className={styles.media_icon_box}>
              {data?.media_list &&
                data?.media_list.map((v, i) => (
                  <a
                    key={i}
                    href={`${setRelativeLink({ link: v?.link })}?ref=footer${
                      pageType === 'powerStation' || pageType === 'hes'
                        ? '_pps'
                        : ''
                    }`}
                    className={styles.media_icon}
                    target="_self"
                    rel="noopener noreferrer"
                  >
                    <Icon
                      aria-label="anker link"
                      iconKey={v.icon}
                      className={cn(styles.icon)}
                    />
                  </a>
                ))}
            </div>
            {infos?.store_payWithEase?.title && (
              <div className={cn(styles.commonInfos, styles.intro)}>
                <div className={styles.payWithEaseContent}>
                  <Text
                    className="w-full max-w-xl break-words"
                    html={infos?.store_payWithEase.iconList}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="min-l:hidden">
          <Subscribe
            device="mobile"
            pageType={pageType}
            metafields={metafields}
            locale={locale}
            data={subScribeData}
            slug={slug}
          ></Subscribe>
        </div>
        <div className={styles.footer_media}>
          <div className={styles.media_icon_box}>
            {data?.media_list &&
              data?.media_list.map((v, i) => (
                <a
                  key={i}
                  href={`${setRelativeLink({ link: v?.link })}?ref=footer${
                    pageType === 'powerStation' || pageType === 'hes'
                      ? '_pps'
                      : ''
                  }`}
                  className={styles.media_icon}
                  target="_self"
                  rel="noopener noreferrer"
                >
                  <Icon
                    aria-label="anker link"
                    iconKey={v.icon}
                    className={cn(
                      styles.icon,
                      'h-[40px] md:w-[28px] l:w-[24px]'
                    )}
                  />
                </a>
              ))}
          </div>
          {COUNTRY_CHOOSE(styles.desktop_hide)}
        </div>
        <div className="mt-[40px] flex items-end justify-between md:mt-[30px] l:flex-col l:items-center xl:mt-[32px]">
          <a
            href={`${setRelativeLink({
              link: data?.ankerin?.link,
            })}?ref=footer${
              pageType === 'powerStation' || pageType === 'hes' ? '_pps' : ''
            }`}
            className={styles.anker_icon}
            target={data?.ankerin?.target}
            rel="noopener noreferrer"
          >
            <Icon
              aria-label="anker link"
              iconKey={data?.ankerin?.icon}
              className={cn(styles.icon_ankerin)}
            />
          </a>
          <div className={styles.footer_icon_box}>
            {data?.icon_list &&
              data?.icon_list.map((v, i) => (
                <a
                  key={i}
                  href={`${setRelativeLink({ link: v?.link })}?ref=footer${
                    pageType === 'powerStation' || pageType === 'hes'
                      ? '_pps'
                      : ''
                  }`}
                  className={styles.anker_icon}
                  target="_self"
                  rel="noopener noreferrer"
                >
                  <Icon
                    aria-label="anker link"
                    iconKey={v.icon}
                    className={cn(styles.footer_icon)}
                  />
                </a>
              ))}
          </div>
        </div>
        <div className="mt-[32px] flex justify-center xs:flex-wrap xs:justify-center md:mt-[32px] md:flex-col md:items-start">
          <p
            className={styles.footer_recordNumber}
            dangerouslySetInnerHTML={{
              __html: data?.recordNumber,
            }}
          ></p>
          <div className="ml-[10px] md:ml-0 md:flex md:flex-wrap md:pt-[5px]">
            {data?.footer_link_list &&
              data?.footer_link_list.map((v, i) => (
                <a
                  key={i}
                  className={styles.footer_link_list}
                  href={`${setRelativeLink({ link: v?.link })}?ref=footer${
                    pageType === 'powerStation' || pageType === 'hes'
                      ? '_pps'
                      : ''
                  }`}
                  target="_self"
                  dangerouslySetInnerHTML={{
                    __html: v.text,
                  }}
                ></a>
              ))}
          </div>
        </div>
      </div>
      <CountryModalComp
        footerCountry={footerCountry}
        pageType={pageType}
        locale={locale}
      />
    </section>
  )
}
export default HesFooter
